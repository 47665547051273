import { Location } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LibraryRouter } from '@app/models/library.router';
import { getPriceTier } from '@app/store/reducers/content.reducer';
import { getMyCurrency } from '@app/store/reducers/profile.reducer';
import { videoType, vimeoCoverImageUrl, youtubeCoverImageUrl } from '@core/helpers/video';
import {
  AccountDetailsType,
  AccountService,
  AuthService,
  BillingService,
  ConfigService,
  ContentBlockType,
  ContentService,
  ContextService,
  FilesService,
  fullUserServiceDetails,
  LanguageService,
  PlatformService,
  ServicesBlockType,
  UserServicesService,
} from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { Store } from '@ngrx/store';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { getLibraryRouteData } from '@store/reducers/library.reducer';
import {
  Content,
  otherSubjectId,
  SubjectsRegistry,
  User,
  UserSegmentEnum,
  UserService,
  Subject as BaseSubject,
  EContentOrder,
  Language,
  CPriceTier,
  CurrencyEnum,
  EContentPanelRoute,
  FindUserServiceStrategyEnum,
  ContentTypeEnum,
  ImageSizeEnum,
  UserStatusEnum,
} from 'lingo2-models';
import { Video as VideoContent } from 'lingo2-models/dist/content/video';
import { DeviceDetectorService } from 'ngx-device-detector';
import { OnUiCover } from 'onclass-ui';
import { combineLatest, Observable, throwError } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';

export type BestEntityDisplayMode = 'teachers' | 'content' | 'user_service';

@Component({
  selector: 'app-best-entity-widget',
  templateUrl: './best-entity-widget.component.html',
  styleUrls: ['./best-entity-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BestEntityWidgetComponent extends ChangableComponent implements OnInit {
  @Input() displayMode: BestEntityDisplayMode;
  public isGuest: boolean;
  public isContent = false;
  public firstLoadContent = true;
  public accountRoute = AccountService.accountRoute;
  public contentRoute = LibraryRouter.contentRouteUniversal;
  public userServiceRoute = LibraryRouter.userServiceRouteUniversal;
  public checkoutModalOpen: boolean;
  public contentModalOpen: boolean;
  public selectService: UserService;
  public selectedContent: User | Content | UserService;
  public route = EContentPanelRoute;
  public teachers = {
    items: [],
    pagination: {
      page: 1,
      pageSize: 3,
    },
    filter: {
      language: [],
      subject_id: [],
      order: EContentOrder.popularity,
      min_services: 1,
    },
  };
  public content: ContentBlockType = {
    items: [],
    loading: false,
    loaded: false,
    pagination: {
      page: 1,
      pageSize: 3,
    },
    filter: {
      language_id: [],
      subject_id: [],
      order: EContentOrder.popularity,
      author_id: [],
    },
  };
  public userServices: ServicesBlockType = {
    items: [],
    loading: false,
    loaded: false,
    pagination: {
      page: 1,
      pageSize: 3,
    },
    filter: {
      use: FindUserServiceStrategyEnum.library,
      language_id: [],
      subject_id: [],
      // ??? order: EContentOrder.popularity,
      author_id: [],
    },
  };
  public schoolOwnersIds = [
    // TODO удалить когда будет роутер для школ
    '73097f9e-f3eb-4a35-883b-020ce9f9aeac', // Darina
    'a42a324b-87c7-46fe-beff-fd911ccf6648', // Maria
    // 'a0ac37d9-c579-47f0-ac79-ae96477c3e90', // Aleksey
  ];

  private _me: User;
  private _subjects: SubjectsRegistry;
  private _languages: Language[];
  private _subjectsIds: number[] = [];
  private _languagesIds: number[] = [];
  private _currency_id: CurrencyEnum;
  private originalUrl: string;

  constructor(
    public errorNotificationService: ErrorNotificationService,
    private authService: AuthService,
    private accountService: AccountService,
    private billingService: BillingService,
    private contextService: ContextService,
    private configService: ConfigService,
    private contentService: ContentService,
    private userServicesService: UserServicesService,
    private languageService: LanguageService,
    private location: Location,
    private store: Store,
    private router: Router,
    public deviceService: DeviceDetectorService,
    protected cdr: ChangeDetectorRef,
    protected platform: PlatformService,
  ) {
    super(cdr, platform);
  }

  ngOnInit() {
    combineLatest([
      this.contextService.me$,
      this.configService.subjectsV2$,
      this.languageService.languages$,
      this.store.select(getLibraryRouteData),
      this.store.select(getMyCurrency),
      // .pipe(
      //     filter((data) => {
      //       logger.log(`ROUTE DATA: `, data);
      //       return !!data;
      //     })
      //   )
    ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: ([me, subjectsRegistry, languages, libraryFilterData, currency_id]) => {
          this._me = me;
          this.isGuest = me && me?.status === UserStatusEnum.guest;
          this._subjects = subjectsRegistry;
          this._languages = languages;
          this._subjectsIds = this.findSubjectsIdByCode(libraryFilterData?.subjectCodes || []);
          this._languagesIds = this.findLanguagesIdByCode(libraryFilterData?.languageCodes || []);
          this._currency_id = currency_id;

          // переопределяем фильтр
          if (this.router.url === '/schools/darina-and-maria') {
            this.content.filter.author_id = this.schoolOwnersIds;
            this.userServices.filter.author_id = this.schoolOwnersIds;
          } else {
            this.teachers.filter.subject_id = this._subjectsIds;
            this.teachers.filter.language = this._languagesIds;

            this.content.filter.subject_id = this._subjectsIds;
            this.content.filter.language_id = this._languagesIds;

            this.userServices.filter.subject_id = this._subjectsIds;
            this.userServices.filter.language_id = this._languagesIds;
          }

          // logger.log('BestEntityWidgetComponent::subscribe', me, subjectsRegistry, languages, libraryFilterData);

          switch (this.displayMode) {
            case 'teachers':
              this.loadTeachers();
              break;
            case 'content':
              this.loadContent();
              break;
            case 'user_service':
              this.loadUserServices();
              break;
            default:
              break;
          }
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        },
      });
  }

  public onToggleContentModal(content: User | Content | UserService = null) {
    this.selectedContent = content;
    this.contentModalOpen = !this.contentModalOpen;

    if (this.displayMode !== 'teachers') {
      this.setAndResetUrl();
    }

    this.detectChanges();
  }

  public goSignup(e: Event, userService: UserService) {
    this.selectService = userService;
    e.stopPropagation();
    if (userService.author_id === this._me?.id) {
      return;
    }

    if (!this.isGuest) {
      this.openCheckoutModal();
    } else {
      this.authService.showAuthModal(() => this.openCheckoutModal());
    }
  }

  private openCheckoutModal() {
    this.checkoutModalOpen = true;
    this.detectChanges();
  }

  public get me() {
    return this._me;
  }

  public fullName(teacher: User): string {
    return `${teacher?.first_name} ${teacher?.last_name}`;
  }

  public subject(content: Content | UserService): string {
    return +content?.subject.id === otherSubjectId ? content.subject_other_name : content?.subject?.title;
  }

  public teachingSubjects(teacher: User): string {
    return teacher.profile?.teaching_subjects
      .map((_s) =>
        +_s.subject_id === otherSubjectId ? _s.subject_other_name : this.findSubjectById(_s.subject_id)?.title,
      )
      .join(', ');
  }

  public typeContent(content: Content): string {
    return ContentService.contentTypeTitle[content.type];
  }

  public get isAsIfTeacher(): boolean {
    return (
      this._me &&
      !AccountService.isTeacher(this._me) &&
      AccountService.hasSegment(this._me, UserSegmentEnum.as_if_teacher)
    );
  }

  public priceWithoutDiscount(amount: number, discount: number): number {
    return +amount / ((100 - +discount) / 100);
  }

  public getPriceTier(price_tier_id: number): Observable<CPriceTier> {
    return this.store.select(getPriceTier, { id: price_tier_id });
  }

  public formatCurrencyAmount(amount: CPriceTier): string {
    return amount?.getForCurrency(this._currency_id).formatFixed() || '0';
  }

  public formatAmount(priceTier: CPriceTier, discount: number): string {
    if (!priceTier) {
      return '';
    }
    const amount = priceTier.getAmountByCurrency(this._currency_id);
    return this.billingService.formatAmount(this.priceWithoutDiscount(amount, discount), this._currency_id);
  }

  public onNavigate(route: string[]) {
    this.router
      .navigate(route)
      .then(() => true)
      .catch(console.error);
  }

  public static accountRoute(account: Partial<User>, extra: string[] = []): string[] {
    return ['/u', account?.slug, ...extra];
  }

  public tutorAvatar(teacher: User): OnUiCover {
    return {
      type: 'avatar',
      img:
        FilesService.getFileBySize(teacher?.userpic, ImageSizeEnum.md)?.url ||
        FilesService.getFileUrlBySize(teacher?.userpic_id, ImageSizeEnum.md),
      title: this.fullName(teacher),
      form: 'circle',
    };
  }

  public contentCover(content: Content): OnUiCover {
    return {
      type: 'cover',
      img: this.prepareCoverImage(content),
      title: content?.title,
      form: 'base',
    };
  }

  public userServiceCover(userService: UserService): OnUiCover {
    return {
      type: 'cover',
      img: this.assetsUrl(userService?.cover?.md?.url),
      title: userService?.title,
      form: 'base',
    };
  }

  public trackByFn(item, index) {
    return index;
  }
  protected prepareCoverImage(content: Content): string {
    if (!content?.cover && content?.type === ContentTypeEnum.video) {
      const _video = content as VideoContent;
      const _type = videoType(_video.media_url);
      switch (_type) {
        case 'youtube':
          return youtubeCoverImageUrl(_video.media_url);
        case 'vimeo':
          return vimeoCoverImageUrl(_video.media_url);
      }
      return;
    }
    return content.cover && content.cover.md ? this.assetsUrl(content.cover.md.url) : null;
  }

  private findSubjectsIdByCode(codes: string[]): number[] {
    return [
      ...this._subjects.other,
      ...this._subjects.user,
      ...this._subjects.native,
      ...this._subjects.foreign,
      ...this._subjects.non_categorized,
      ...this._subjects.recent,
    ]
      .filter((_s) => !!codes.find((_sc) => _sc === _s.code))
      .map((_subjects) => _subjects.id);
  }

  private findSubjectById(id: number): BaseSubject {
    return [
      ...this._subjects.other,
      ...this._subjects.user,
      ...this._subjects.native,
      ...this._subjects.foreign,
      ...this._subjects.non_categorized,
      ...this._subjects.recent,
    ].find((_s) => +_s.id === id);
  }

  private findLanguagesIdByCode(codes: string[]): number[] {
    return this._languages.filter((_l) => !!codes.find((_sc) => _sc === _l.code)).map((_languages) => _languages.id);
  }

  private loadTeachers(firstLoadContent = true) {
    const details: AccountDetailsType[] = [
      'id',
      'country',
      'slug',
      'first_name',
      'last_name',
      'userpic_id',
      'profile',
      'segments',
      'stats',
      'created_at',
    ];

    this.accountService
      .findTeachers(this.teachers.filter, this.teachers.pagination, details)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (teachers) => {
          this.teachers.items = teachers.results;

          // Если ответ пришел пустым, сбрасываем фильтр дисциплин и делаем повторный запрос чтобы хоть что-то показать
          // begin
          if (!!teachers.results.length && firstLoadContent) {
            this.isContent = true;
          } else {
            this.firstLoadContent = false;
            this.isContent = false;
            this.teachers.filter.subject_id = [];
            this.teachers.filter.language = [];

            if (!this.teachers.items.length && firstLoadContent) {
              this.loadTeachers(false);
            }
          }
          // end

          // logger.log('BestEntityWidgetComponent::loadTeachers()', teachers);
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        },
      });
  }

  private loadUserServices(firstLoadContent = true) {
    this.userServicesService
      .getServices(this.userServices.filter, this.userServices.pagination, fullUserServiceDetails)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (response) => {
          this.userServices.items = response.results;
          this.userServices.pagination = response.pagination;
          this.isContent = !!response.results.length;

          // Если ответ пришел пустым, сбрасываем фильтр дисциплин и делаем повторный запрос чтобы хоть что-то показать
          // begin
          if (!!response.results.length && firstLoadContent) {
            this.isContent = true;
          } else {
            this.firstLoadContent = false;
            this.isContent = false;
            this.userServices.filter.subject_id = [];
            this.userServices.filter.language_id = [];

            if (!this.userServices.items.length && firstLoadContent) {
              this.loadUserServices(false);
            }
          }
          // end

          // logger.log('BestEntityWidgetComponent::loadUserServices()', userServices);
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          this.userServices.loading = false;
          this.detectChanges();
        },
      });
  }

  private loadContent(firstLoadContent = true) {
    this.contentService
      .getContent(this.content.filter, this.content.pagination)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (contentResult) => {
          this.content.items = contentResult.results;
          this.content.pagination = contentResult.pagination;
          this.isContent = !!contentResult.results.length;

          // Если ответ пришел пустым, сбрасываем фильтр дисциплин и делаем повторный запрос чтобы хоть что-то показать
          // begin
          if (!!contentResult.results.length && firstLoadContent) {
            this.isContent = true;
          } else {
            this.firstLoadContent = false;
            this.isContent = false;
            this.content.filter.subject_id = [];
            this.content.filter.language_id = [];

            if (!this.content.items.length && firstLoadContent) {
              this.loadContent(false);
            }
          }
          // end

          // logger.log('BestEntityWidgetComponent::loadContent()', contentResult);
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        },
      });
  }

  public get getSubjectId() {
    const content = this.selectedContent as User;
    return content.profile.teaching_subjects[0]?.subject_id;
  }

  private setAndResetUrl() {
    let url: string;
    switch (this.displayMode) {
      case 'content':
        url = url = this.contentRoute(this.selectedContent as Content);
        break;
      case 'user_service':
        url = this.userServiceRoute(this.selectedContent as UserService);
        break;
      default:
        return;
    }

    const currentUrl = this.location.path(false);
    if (this.contentModalOpen) {
      if (currentUrl !== url) {
        if (!this.originalUrl) {
          this.originalUrl = currentUrl;
        }
        this.location.replaceState(url);
      }
    } else {
      if (this.originalUrl) {
        this.location.replaceState(this.originalUrl);
        this.originalUrl = null;
      }
    }
  }
}
