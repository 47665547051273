import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AccountSharedModule } from '@app/account/account-shared.module';
import { PaymentCheckoutWizardModule } from '@app/account/shared/payment-checkout-wizard/payment-checkout-wizard.module';
import { ContentCardModule } from '@app/library/content-card/content-card.module';
import { LibraryElementsModule } from '@app/library/library-elements.module';
import { UserServiceCardFullModule } from '@app/meetings/user-service-card-full/user-service-card-full.module';
import { TeacherBookingSurfaceBlockComponent } from '@app/teachers/teacher-card/teacher-booking-surface-block/teacher-booking-surface-block.component';
import { BestEntityWidgetComponent } from '@core/components/ad/best-entity-widget/best-entity-widget.component';
import { MobileModalModule } from '@core/components/mobile-modal/mobile-modal.module';
import { ModalModule } from '@core/components/modal/modal.module';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@shared/icon/icon.component';
import { RatingComponent } from '@shared/rating/rating.component';
import { OnUiButtonModule, OnUiCoverModule } from 'onclass-ui';

@NgModule({
  declarations: [BestEntityWidgetComponent],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    ModalModule,
    MobileModalModule,
    LibraryElementsModule,
    UserServiceCardFullModule,
    OnUiCoverModule,
    OnUiButtonModule,
    AccountSharedModule,
    PaymentCheckoutWizardModule,
    TeacherBookingSurfaceBlockComponent,
    RatingComponent,
    ContentCardModule,
    IconComponent,
  ],
  exports: [BestEntityWidgetComponent],
})
export class BestEntityWidgetModule {}
