<section [ngSwitch]="displayMode" class="best-entity-widget">
  <ng-template ngSwitchCase="teachers">
    <h2 class="title bold">{{ 'best-entity-widget.titles.teachers' | translate }}</h2>
    <ng-template [ngIf]="isContent" [ngIfElse]="noContent">
      <div class="cards">
        <div *ngFor="let teacher of teachers.items; trackBy: trackByFn" class="card-wrap">
          <ng-container *ngTemplateOutlet="teacherCard; context: { teacher: teacher }"></ng-container>
        </div>
      </div>

      <a [routerLink]="'/' + route.tutors"
        class="see-all-link normal">{{ 'best-entity-widget.see-all-link' | translate }}</a>
    </ng-template>

    <ng-template #noContent>
      <span class="subtitle normal">{{ 'best-entity-widget.subtitles.teachers' | translate }}</span>
      <app-icon class="skeleton-icon" icon="best-entity-skeleton"></app-icon>

      <ng-template [ngIf]="isAsIfTeacher" [ngIfElse]="student">
        <div class="actions-wrap">
          <div class="button-wrap">
            <on-ui-button class="primary"
                        label="best-entity-widget.actions.teachers.action1"
                        state="default"
                        (onclick)="onNavigate(['/profile'])">
            </on-ui-button>
          </div>

          <span class="action normal">{{ 'best-entity-widget.actions.teachers.action2' | translate }}</span>
        </div>
      </ng-template>

      <ng-template #student>
        <span class="subtitle normal">{{ 'best-entity-widget.interested-in' | translate }}</span>

        <div class="cards">
          <div *ngFor="let teacher of teachers.items; trackBy: trackByFn" class="card-wrap">
            <ng-container *ngTemplateOutlet="teacherCard; context: { teacher: teacher }"></ng-container>
          </div>
        </div>

        <a [routerLink]="'/' + route.tutors"
          class="see-all-link normal">{{ 'best-entity-widget.see-all-link' | translate }}</a>
      </ng-template>
    </ng-template>
  </ng-template>

  <ng-template ngSwitchCase="content">
    <h2 class="title bold">{{ 'best-entity-widget.titles.content' | translate }}</h2>
    <ng-template [ngIf]="isContent" [ngIfElse]="noContent">
      <div class="cards">
        <div *ngFor="let content of content.items; trackBy: trackByFn" class="card-wrap">
          <ng-container *ngTemplateOutlet="contentCard; context: { content: content }"></ng-container>
        </div>
      </div>

      <a routerLink="/lessons" class="see-all-link normal">{{ 'best-entity-widget.see-all-link' | translate }}</a>
    </ng-template>

    <ng-template #noContent>
      <span class="subtitle normal">{{ 'best-entity-widget.subtitles.content' | translate }}</span>
      <app-icon class="skeleton-icon" icon="best-entity-skeleton"></app-icon>

      <ng-template [ngIf]="isAsIfTeacher" [ngIfElse]="student">
        <div class="actions-wrap">
          <on-ui-button class="primary"
                        label="best-entity-widget.actions.content.action1"
                        state="default"
                        (onclick)="onNavigate(['/constructor'])">
          </on-ui-button>
        </div>
      </ng-template>

      <ng-template #student>
        <span class="subtitle normal">{{ 'best-entity-widget.interested-in' | translate }}</span>

        <div class="cards">
          <div *ngFor="let content of content.items; trackBy: trackByFn" class="card-wrap">
            <ng-container *ngTemplateOutlet="contentCard; context: { content: content }"></ng-container>
          </div>
        </div>

        <a routerLink="/lessons" class="see-all-link normal">{{ 'best-entity-widget.see-all-link' | translate }}</a>
      </ng-template>
    </ng-template>
  </ng-template>

  <ng-template ngSwitchCase="user_service">
    <h2 class="title bold">{{ 'best-entity-widget.titles.user-services' | translate }}</h2>
    <ng-template [ngIf]="isContent" [ngIfElse]="noContent">
      <div class="cards">
        <div *ngFor="let userService of userServices.items; trackBy: trackByFn" class="card-wrap">
          <ng-container *ngTemplateOutlet="userServiceCard; context: { userService: userService }"></ng-container>
        </div>
      </div>

      <a [routerLink]="'/' + route.classes" class="see-all-link normal">{{ 'best-entity-widget.see-all-link' | translate }}</a>
    </ng-template>

    <ng-template #noContent>
      <span class="subtitle normal">{{ 'best-entity-widget.subtitles.user-services' | translate }}</span>
      <app-icon class="skeleton-icon" icon="best-entity-skeleton"></app-icon>

      <ng-template [ngIf]="isAsIfTeacher" [ngIfElse]="student">
        <div class="actions-wrap">
          <span class="action normal">{{ 'best-entity-widget.actions.user-service.action1' | translate }}</span>
        </div>
      </ng-template>

      <ng-template #student>
        <span class="subtitle normal">{{ 'best-entity-widget.interested-in' | translate }}</span>

        <div class="cards">
          <div *ngFor="let userService of userServices.items; trackBy: trackByFn" class="card-wrap">
            <ng-container *ngTemplateOutlet="userServiceCard; context: { userService: userService }"></ng-container>
          </div>
        </div>

        <a [routerLink]="'/' + route.classes" class="see-all-link normal">{{ 'best-entity-widget.see-all-link' | translate }}</a>
      </ng-template>
    </ng-template>
  </ng-template>
</section>


<ng-template #teacherCard let-teacher="teacher">
  <section class="teacher-card" (click)="onToggleContentModal(teacher)">
    <div class="avatar-wrap">
      <on-ui-cover [cover]="tutorAvatar(teacher)"></on-ui-cover>
    </div>
    <div class="info-block">
      <span class="full-name bold">{{ fullName(teacher) }}</span>
      <span class="teaching-subject normal">{{ teachingSubjects(teacher) }}</span>
      <div class="stats-wrap">
        <app-rating [stats]="teacher?.stats"></app-rating>
        <ng-template [ngIf]="teacher?.stats?.min_price_tier">
          <span class="price normal">
            {{ 'best-entity-widget.from' | translate }}
            {{ formatCurrencyAmount(getPriceTier(teacher?.stats?.min_price_tier) | async) }}
          </span>
        </ng-template>
      </div>
    </div>
  </section>
</ng-template>

<ng-template #contentCard let-content="content">
  <section class="content-card" (click)="onToggleContentModal(content)">
    <div class="cover-wrap">
      <on-ui-cover [cover]="contentCover(content)"></on-ui-cover>
    </div>
    <div class="info-block">
      <span class="subject bold">{{ subject(content) }}</span>
      <span class="title-content bold">{{ content?.title }}</span>
      <span class="type-content normal">{{ typeContent(content) | translate }}</span>
    </div>
  </section>
</ng-template>

<ng-template #userServiceCard let-userService="userService">
  <section class="user-service-card" (click)="onToggleContentModal(userService)">
    <div class="cover-wrap">
      <on-ui-cover [cover]="userServiceCover(userService)"></on-ui-cover>
      <ng-template [ngIf]="userService?.discount">
        <div class="discount bold">%</div>
      </ng-template>
    </div>
    <div class="info-block">
      <div class="info">
        <span class="subject bold">{{ subject(userService) }}</span>
        <span class="user-service-title bold">{{ userService?.title }}</span>
        <div class="price-block">
          <span class="price normal">
            {{ formatCurrencyAmount(getPriceTier(userService?.price_tier) | async) }}/{{ 'best-entity-widget.hour' | translate }}
          </span>&nbsp;
          <ng-template [ngIf]="userService?.discount">
            <span class="price old-price normal">
              {{ formatAmount(getPriceTier(userService?.price_tier) | async, userService?.discount) }}
            </span>
          </ng-template>
        </div>
      </div>
      <span class="action normal" (click)="goSignup($event, userService)">{{ 'best-entity-widget.book-now' | translate }} &rarr;</span>
    </div>
  </section>
</ng-template>

<app-modal *ngIf="deviceService.isDesktop() && checkoutModalOpen"
           [canClose]="false"
           (closed)="checkoutModalOpen = false">
  <app-modal-body>
    <app-payment-checkout-wizard [content]="selectService"
                                 [user]="selectService.author"
                                 [me]="me"
                                 (closed)="checkoutModalOpen = false">
    </app-payment-checkout-wizard>
  </app-modal-body>
</app-modal>

<app-mobile-modal *ngIf="!deviceService.isDesktop() && checkoutModalOpen"
                  [titleVisible]="false" [withCloseButton]="true"
                  [customTitle]="'user-profile.promo-info.sign-lesson' | translate"
                  (closed)="checkoutModalOpen = false">
  <app-mobile-modal-body>
    <div class="checkout-wizard-wrap">
      <app-payment-checkout-wizard [content]="selectService"
                                   [user]="selectService.author"
                                   [me]="me"
                                   (closed)="checkoutModalOpen = false">
      </app-payment-checkout-wizard>
    </div>
  </app-mobile-modal-body>
</app-mobile-modal>

<ng-template #contentInModal>
  <ng-container [ngSwitch]="displayMode">
    <ng-template ngSwitchCase="teachers">
      <app-user-profile-page
        [userName]="selectedContent.slug"
        [selectedSubjectId]="getSubjectId"
        [isModal]="selectedContent">
      </app-user-profile-page>
    </ng-template>
    <ng-template ngSwitchCase="content">
      <app-content-card
        class="modal"
        [item_id]="selectedContent.id"
        [show]="true"
        [canClose]="false"
        [inline]="false"
        [active]="false"
        [meta]="true">
      </app-content-card>
    </ng-template>
    <ng-template ngSwitchCase="user_service">
      <app-user-service-card-full [userService]="selectedContent"
                                  [modal]="true">
      </app-user-service-card-full>
    </ng-template>
  </ng-container>
</ng-template>

<app-modal *ngIf="deviceService.isDesktop() && contentModalOpen"
           [canClose]="false"
           [additionalHeight]="80"
           (closed)="onToggleContentModal()">
  <app-modal-body>
    <ng-container *ngTemplateOutlet="contentInModal"></ng-container>
  </app-modal-body>
  <app-modal-ad *ngIf="displayMode==='teachers'">
    <app-teacher-booking-surface-block [teacher]="selectedContent"></app-teacher-booking-surface-block>
  </app-modal-ad>
</app-modal>

<app-mobile-modal *ngIf="!deviceService.isDesktop() && contentModalOpen"
                  [titleVisible]="false"
                  (closed)="onToggleContentModal()">
  <app-mobile-modal-body>
    <ng-container *ngTemplateOutlet="contentInModal"></ng-container>
  </app-mobile-modal-body>
</app-mobile-modal>
